import React from "react";
import { NextSeo } from "next-seo";
import styled from "styled-components";

import {
  MAIN_PADDING,
  MAIN_PADDING_2,
  MAX_CONTENT_WIDTH,
  BREAKPOINTS,
} from "../constants";

import translations from "../config/translations";

const Wrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH};
  margin: ${MAIN_PADDING_2} auto;
  text-align: center;
  padding: 0 25px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    margin: ${MAIN_PADDING} auto;
  }
`;

const NotFound = (props) => {
  return <div>
    <NextSeo
      title={
        translations["404 Not Found - Kaubad.ee"][props.locale || 'en']
      }
      description={
        translations["404 Not Found - Kaubad.ee"][props.locale || 'en']
      }
    />
    <Wrapper>
      <h1>{translations["Page not found - Kaubad.ee"][props.locale || 'en']}</h1>
    </Wrapper>
  </div>
};

export default NotFound;
